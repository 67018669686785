import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { isString } from 'lodash';
import {
  reduxForm,
  Field,
  SubmissionError,
  formValueSelector,
} from 'redux-form/immutable';
import { FORM_ADD_CONSUMPTION_POINT } from '../../constants/formKeys';
import {
  meteringPointType,
  meteringPointDataSource,
} from '../../constants/classificators';
import { Row, Col, message } from 'antd';
import { gas, meteringPointFields } from '../../constants/meteringPoint';
import { DateField, ClassificatorField, SelectField } from '../../atoms';
import { getEntityById } from '../../utils/asyncHelpers';
import {
  measurement as meteringSchema,
  avpMeteringPointScheme,
} from '../../schemas';
import { goToMeteringPointList } from '../../utils/gotoLink';
import { processAsyncResult } from '../../utils/formHelpers';
import { consumerFields } from '../../constants/consumer';
import { getSelectedPointId } from '../../reducers/consumptionPointAddReducer';
import { updateConsumptionPoint } from '../../actions/actionCreators/meteringPointActionCreators';
import validate from './validationSchema';
import classifiersStore from '../../utils/classifiersStore';

class ConsumptionPointAdd extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    selectedMeteringPoint: PropTypesImmutable.map,
    ownerId: PropTypes.number.isRequired,
  };

  render() {
    const { t, selectedMeteringPoint } = this.props;

    const isAvp =
      selectedMeteringPoint &&
      selectedMeteringPoint.get(meteringPointFields.DATA_SOURCE) ===
        meteringPointDataSource.AVP;

    const options = classifiersStore.getBooleanProperty('IS_HYDROGEN_ENABLED')
      ? [
          {
            value: gas.HYDROGEN,
            label: t('info.gas.hydrogen'),
          },
          {
            value: gas.BIOMETHANE,
            label: t('info.gas.biomethane'),
          },
        ]
      : [
          {
            value: gas.BIOMETHANE,
            label: t('info.gas.biomethane'),
          },
        ];

    return (
      <form>
        <Row gutter={32}>
          <Col span={8}>
            <Field
              name={meteringPointFields.START_DATE}
              component={DateField}
              label={t('info.startDate')}
              required
              disabled={isAvp}
            />
          </Col>
          <Col span={8}>
            <Field
              name={meteringPointFields.END_DATE}
              component={DateField}
              label={t('info.endDate')}
              disabled={isAvp}
            />
          </Col>
          <Col span={8}>
            <Field
              name={meteringPointFields.GAS}
              component={SelectField}
              required
              label={t('info.gas.title')}
              options={options}
            />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={8}>
            <Field
              name={meteringPointFields.CONSUMPTION_TYPE}
              component={ClassificatorField}
              type={ClassificatorField.types.consumptionUsage}
              label={t('info.consumptionType')}
              required
            />
          </Col>
        </Row>
      </form>
    );
  }
}

const onSubmitFail = errors => {
  if (isString(errors)) {
    message.error(errors);
  }
};

const onSubmit = async (values, dispatch, ownProps) => {
  if (!ownProps.selectedMeteringPoint)
    throw new SubmissionError(ownProps.t('info.metringPointError'));

  if (!ownProps.ownerId)
    throw new SubmissionError(ownProps.t('info.ownerShouldBeFilled'));

  if (!ownProps.consumer)
    throw new SubmissionError(ownProps.t('info.consumerShouldBeFilled'));

  const consumerJs = ownProps.consumer.toJS();
  if (typeof consumerJs[consumerFields.ID] === 'string') {
    delete consumerJs[consumerFields.ID];
  }
  const result = await dispatch(
    updateConsumptionPoint(
      values
        .merge({
          [meteringPointFields.LEGAL_ENTITY_ID]: ownProps.ownerId,
          [meteringPointFields.CONSUMER]: consumerJs,
          [meteringPointFields.METERING_POINT_TYPE]:
            meteringPointType.CONSUMPTION,
        })
        .delete(meteringPointFields.ID)
        .toJS()
    )
  );
  processAsyncResult(result);
  goToMeteringPointList();
};

const valueSelector = formValueSelector(FORM_ADD_CONSUMPTION_POINT);
const mapStateToProps = state => {
  const selectedPointId = getSelectedPointId(state);

  let point = getEntityById(state, avpMeteringPointScheme, selectedPointId);

  if (!point) {
    point = getEntityById(state, meteringSchema, selectedPointId);
  }

  const formValues = {
    gasType: valueSelector(state, meteringPointFields.GAS),
    startDate: valueSelector(state, meteringPointFields.START_DATE),
    endDate: valueSelector(state, meteringPointFields.END_DATE),
    consumptionType: valueSelector(state, meteringPointFields.CONSUMPTION_TYPE),
  };
  let initialValues = {};
  let pointValues = point;

  if (point) {
    pointValues = point.toJS();
    if (
      point.get(meteringPointFields.DATA_SOURCE) === meteringPointDataSource.AVP
    ) {
      pointValues[meteringPointFields.GAS] =
        formValues[meteringPointFields.GAS];
      pointValues[meteringPointFields.CONSUMPTION_TYPE] =
        formValues[meteringPointFields.CONSUMPTION_TYPE];
    }
  }
  initialValues = {
    ...formValues,
    ...pointValues,
  };
  return {
    selectedMeteringPoint: point,
    initialValues: initialValues,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_ADD_CONSUMPTION_POINT,
    onSubmit,
    onSubmitFail,
    validate,
    enableReinitialize: true,
  })(ConsumptionPointAdd)
);
