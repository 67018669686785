import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import {
  transactionFields,
  transactionParticipantFields,
} from '../../constants/transaction';
class TransactionParticipantInformation extends React.Component {
  static propTypes = {
    info: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    isSender: PropTypes.bool,
  };

  static defaultProps = {
    isSender: false,
  };

  render() {
    const { t, isSender, info } = this.props;

    const prefix = isSender ? 'sender' : 'receiver';
    const participantData = isSender
      ? info[transactionFields.SENDER_INFORMATION]
      : info[transactionFields.RECEIVER_INFORMATION];
    return (
      <React.Fragment>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <b>{t(`${prefix}.name`)}: </b>
            {participantData[transactionParticipantFields.NAME]}
          </Col>
          <Col span={8}>
            <b>{t(`${prefix}.code`)}: </b>
            {participantData[transactionParticipantFields.CODE]}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default TransactionParticipantInformation;
